<template>
  <b-modal
    ref="nameInputModal"
    title="กรุณาตั้งชื่อกลุ่มรายการ"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
    ok-title="สร้างรายการที่ใช้บ่อย"
    cancel-title="ยกเลิก"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group invalid-feedback="กรุณาใส่ชื่อ" :state="nameState">
        <b-form-input id="name-input" v-model="name" :state="nameState" required></b-form-input>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      nameState: null,
      submittedNames: []
    };
  },
  methods: {
    openModal() {
      this.$refs["nameInputModal"].show();
    },
    hideModal() {
      this.$refs["nameInputModal"].hide();
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.$emit("submitName", this.name);

      this.$nextTick(() => {
        this.hideModal();
      });
    }
  }
};
</script>