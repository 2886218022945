<template>
  <div>
    <b-row
      ><b-col cols="12" md="4" lg="5" class="mb-3">
        <b-form-group
          block
          label="วันที่:"
          label-cols="2"
          label-align="right"
          invalid-feedback="*กรุณาเลือกวันที่"
          :state="Boolean(dateRange[0] && dateRange[1])"
        >
          <date-picker
            v-model="dateRange"
            type="date"
            range
            format="DD MMM YY"
            :shortcuts="dateRangeShortcuts"
            :disabled-date="notAfterToday"
            placeholder="เลือกวันที่ต้องการค้นหา"
            input-class="form-control"
          >
          </date-picker> </b-form-group
      ></b-col>
      <b-col cols="12" md="4" lg="4" class="">
        <b-form-group label="แพทย์:" label-align="right" label-cols="3">
          <b-form-select
            v-model="doctorId"
            :options="getDoctorList"
            value-field="uid"
            text-field="fullName"
          >
            <template v-slot:first>
              <b-form-select-option :value="null"
                >แพทย์ทั้งหมด</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="3" class="mb-3">
        <b-button
          class="float-right mb-2 ml-2"
          variant="primary"
          @click="fetchLabReport"
        >
          แสดงข้อมูล
        </b-button>
        <b-button
          class="float-right"
          variant="info"
          @click="excelExport('BranchLabReport')"
          ><i class="fas fa-file-excel"></i
        ></b-button>
      </b-col>
    </b-row>
    <b-table
      id="BranchLabReport"
      ref="BranchLabReport"
      small
      sort-icon-left
      hover
      head-variant="light"
      :items="fileteredLabExpenseList"
      :fields="fields"
      sort-by="creationDt"
      v-model="visibleRows"
      :sort-desc="true"
      :responsive="true"
      show-empty
      sticky-header="calc(100vh - 210px)"
      @row-clicked="onRowClicked"
    >
      <template #cell(index)="row">{{ row.index + 1 }}.</template>

      <template #custom-foot>
        <b-tr variant="secondary" class="text-bold text-right">
          <td class="text-left">รวม</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>

          <td>{{ sum("dfAmount") }}</td>
          <td>{{ sum("cfAmount") }}</td>
          <td>{{ sum("price") }}</td>
        </b-tr>
      </template>
      <template #empty="">
        <p class="text-center text-muted my-2">ไม่มีข้อมูลให้แสดง</p>
      </template>
      <template #cell(price)="row">
        <b-tooltip
          :target="'row-' + row.index"
          triggers="manual"
          placement="left"
          customClass="custom-tooltip"
          @shown="onTooltipShown"
          @hidden="onTooltipHidden"
        >
          <LabImageGallery
            v-if="isContainImage"
            cardClass="mb-0"
            photoSize="xs"
            imgStyle="min-width: 100px; height: auto; object-fit: contain"
            category="Invoice"
          />
          <p v-else class="p-3 mb-0">ไม่มีรูป</p>
        </b-tooltip>
        <span :id="'row-' + row.index">{{ row.value }}</span>
      </template>
      <template v-if="hasSubscribed" #table-caption>Click เพื่อดูรูป</template>
    </b-table>

    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/main";

import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";
import LabImageGallery from "@/components/card/Gallery";
import XLSX from "xlsx";

export default {
  name: "BranchLabReport",
  components: {
    Dialog,
    Loading,
    LabImageGallery,
  },
  data() {
    return {
      isLoading: false,
      dateRange: [],
      doctorId: null,

      fields: [
        {
          key: "creationDt",
          label: "วันที่สร้าง",
          sortable: true,
          class: "narrow-spacing",
          formatter: "formatDate",
        },
        {
          key: "dfDate",
          label: "วันที่คำนวน DF",
          sortable: true,
          class: "narrow-spacing",
          formatter: "formatDate",
        },
        {
          key: "recDate",
          label: "วันที่รับงาน",
          sortable: true,
          class: "narrow-spacing",
          formatter: "formatDate",
        },

        {
          key: "invoiceNo",
          label: "เลขที่ invoice",
          class: "narrow-spacing",
        },
        {
          key: "mLab",
          label: "งานแลป",
          class: "narrow-spacing",
          formatter(i) {
            return i?.nameTh || "";
          },
        },

        {
          key: "mCompanyLab",
          label: "ชื่อบริษัท",
          class: "narrow-spacing",
          formatter(i) {
            return i?.nameTh || "";
          },
        },
        {
          key: "patient",
          label: "ชื่อคนไข้",
          class: "narrow-spacing",
          formatter(i) {
            return `${i.firstNameTh} ${i.lastNameTh}`;
          },
        },
        {
          key: "doctor",
          label: "ชื่อแพทย์",
          class: "narrow-spacing",
          formatter(i) {
            return i.fullName;
          },
        },

        {
          key: "dfAmount",
          label: "หักแพทย์",
          class: "narrow-spacing text-right",
        },
        {
          key: "cfAmount",
          label: "หักคลินิก",
          class: "narrow-spacing text-right",
        },

        {
          key: "price",
          label: "รวม(บาท)",
          class: "narrow-spacing text-right",
        },
      ],
      visibleRows: [],
      loadingTime: 0,
      reportList: [],
      tooltipOpen: false,
      mediaFiles: [],
    };
  },
  created() {
    this.init();
    document.addEventListener("click", this.handleClickOutside);
  },
  computed: {
    ...mapGetters({
      getReportDateRange: "moduleReport/getReportDateRange",
      getDoctorList: "moduleUser/getDoctorList",
      dateRangeShortcuts: "moduleMaster/getDateRangeShortcuts",
      getLabExpenseList: "moduleLab/getLabExpenseList",
      hasSubscribed: "moduleUser/hasSubscribed",
    }),
    fileteredLabExpenseList() {
      if (this.doctorId === null) return this.reportList;
      return this.reportList.filter(i => i.uid === this.doctorId);
    },
    dateFrom() {
      return this.dateRange[0]
        ? moment(this.dateRange[0]).format("DD/MM/YYYY")
        : "";
    },
    dateTo() {
      return this.dateRange[1]
        ? moment(this.dateRange[1]).format("DD/MM/YYYY")
        : "";
    },
    today() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      return today;
    },
    isContainImage() {
      return this.mediaFiles.length > 0;
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchLabExpenseList: "moduleLab/fetchLabExpenseList",
      fecthMediaFiles: "moduleMediaFile/fecthMediaFiles",
    }),
    init() {},
    moment() {
      return moment();
    },
    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("D MMM YY");
      } else {
        return "";
      }
    },
    formatTime(date) {
      if (date) {
        return moment(date).locale("th").format("HH:mm");
      } else {
        return "";
      }
    },

    // showDialogToast(variant, textDetail) {
    //   this.$refs.Dialog.showToast(variant, textDetail);
    // },
    sum(key) {
      let splitStr = key.split(".");
      return this.visibleRows.reduce((accum, item) => {
        let data;
        if (splitStr.length > 1) {
          if (
            item[splitStr[0]] &&
            item[splitStr[0]][splitStr[1]] &&
            item[splitStr[0]][splitStr[1]][splitStr[2]]
          ) {
            data = item[splitStr[0]][splitStr[1]][splitStr[2]];
          } else {
            data = 0;
          }
        } else {
          data = item[key];
        }
        if (typeof data != "number") return null;
        return Math.round((accum + data) * 20) / 20;
      }, 0);
    },
    async fetchLabReport() {
      this.isLoading = true;
      await this.fetchLabExpenseList({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        event: {
          fromCreationDate: this.formatStartDate(this.dateRange[0]) || null,
          toCreationDate: this.formatEndDate(this.dateRange[1]) || null,
        },
      })
        .then(res => {
          this.reportList = res.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    excelExport(id) {
      document.querySelectorAll(".sr-only").forEach(e => e.remove());
      var tbl = document.getElementById(id);
      var ws = XLSX.utils.table_to_sheet(tbl);
      let filename = "report.xlsx";
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws);
      XLSX.writeFile(wb, filename, {
        // type: "string",
      });
    },
    formatStartDate(date) {
      return date ? moment(date).startOf("day").format() : null;
    },
    formatStartDateUtc(date) {
      return date ? moment(date).startOf("day").utc(true).format() : null;
    },
    formatEndDate(date) {
      return date ? moment(date).endOf("day").format() : null;
    },
    formatEndDateUtc(date) {
      return date ? moment(date).endOf("day").utc(true).format() : null;
    },
    async onRowClicked(item, index, event) {
      if (this.hasSubscribed) {
        const data = await this.fecthMediaFiles({
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          params: { labId: item.id, limit: 100 },
        });
        this.mediaFiles = data;
        await this.$nextTick();

        this.$root.$emit("bv::show::tooltip", "row-" + index);
        return false;
      }
    },
    handleClickOutside(event) {
      if (this.tooltipOpen) {
        const clickedInsideTooltip =
          event.target.closest(".b-tooltip") ||
          event.target.closest('[id^="row-"]');
        if (!clickedInsideTooltip) {
          this.$root.$emit("bv::hide::tooltip");
        }
      }
    },
    onTooltipShown() {
      this.tooltipOpen = true;
    },
    onTooltipHidden() {
      this.tooltipOpen = false;
    },
  },

  mounted() {
    if (this.getReportDateRange[0] && this.getReportDateRange[1]) {
      this.dateRange = this.getReportDateRange;
    } else {
      this.dateRange = [this.today, this.today];
    }
  },
  updated() {},
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style>
.sr-only {
  display: none;
}
.custom-tooltip .tooltip-inner {
  max-width: 300px; /* Adjust as needed */
  padding: 0px;
}
</style>
