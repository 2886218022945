<template>
  <div>
    <b-row
      ><b-col cols="12" md="6">
        <b-form-group
          block
          label="วันที่:"
          label-cols="3"
          label-align="right"
          invalid-feedback="*กรุณาเลือกวันที่"
          :state="Boolean(dateRange[0] && dateRange[1])"
        >
          <date-picker
            v-model="dateRange"
            type="date"
            range
            format="DD MMM YY"
            :shortcuts="dateRangeShortcuts"
            :disabled-date="notAfterToday"
            placeholder="เลือกวันที่ต้องการค้นหา"
            input-class="form-control"
          >
          </date-picker></b-form-group
      ></b-col>
      <b-col cols="12" md="6">
        <b-form-group
          class="mb-2"
          label="ประเภท:"
          label-cols="3"
          label-align="right"
        >
          <b-form-checkbox-group class="mt-1" v-model="itemsType">
            <b-form-checkbox value="01">รักษาทั่วไป</b-form-checkbox>
            <b-form-checkbox value="02">แพ็กเกจจัดฟัน</b-form-checkbox>
            <b-form-checkbox value="03">ยา และอุปกรณ์</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="สถานะ:" label-cols="3" label-align-sm="right">
          <b-form-select
            class="col"
            v-model="itemsStatus"
            :options="options"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-button
          class="float-right mb-2 ml-2"
          variant="primary"
          @click="fetchReport"
        >
          แสดงข้อมูล
        </b-button>
        <b-button
          class="float-right"
          variant="info"
          @click="excelExport('OverdueItemTable')"
          ><i class="fas fa-file-excel"></i
        ></b-button>
      </b-col>
    </b-row>
    <b-table
      id="OverdueItemTable"
      ref="OverdueItemTable"
      small
      sort-icon-left
      head-variant="dark"
      :busy="tableBusy"
      :items="filteredOverdueList"
      :fields="fields"
      sort-by="creationDt"
      v-model="visibleRows"
      :sort-desc="true"
      :responsive="true"
      show-empty
      sticky-header="calc(100vh - 210px)"
    >
      <template #cell(index)="row">{{ row.index + 1 }}.</template>
      <template #cell(creationDt)="row">{{
        formatDate(row.item.creationDt)
      }}</template>
      <template #cell(time)="row">{{
        formatTime(row.item.creationDt)
      }}</template>

      <template #cell(treatType)="row">{{ formatType(row.value) }}</template>
      <template #cell(overdueDelFlag)="row">{{
        row.value ? "ลบแล้ว" : "ปกติ"
      }}</template>
      <template #cell(overdueDelFullName)="row">{{
        getFullname(row.item)
      }}</template>
      <template #cell(overdueDelDate)="row">{{
        formatDate(row.value) + " " + formatTime(row.value)
      }}</template>

      <template #custom-foot>
        <b-tr variant="secondary" class="text-bold text-right">
          <td class="text-left">รวม</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>

          <td>{{ sum("amount") }}</td>
          <td>{{ sum("paid") }}</td>
          <td>{{ sum("overdue") }}</td>
          <td></td>
          <td></td>
          <td></td>
        </b-tr>
      </template>
      <template #empty="">
        <p class="text-center text-muted my-2">ไม่มีข้อมูลให้แสดง</p>
      </template>
    </b-table>

    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/main";

import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";

import XLSX from "xlsx";

export default {
  name: "OverdueItemReport",
  components: {
    Dialog,
    Loading,
  },
  data() {
    return {
      isLoading: false,

      dateRange: [],
      //table
      tableBusy: false,
      overdueList: [],
      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "creationDt",
          label: "วันที่",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "time",
          label: "เวลา",
          class: "narrow-spacing",
        },
        {
          key: "dn",
          label: "DN.",
          class: "narrow-spacing",
        },
        {
          key: "patientFullName",
          label: "ชื่อคนไข้",
          class: "narrow-spacing",
        },
        {
          key: "itemName",
          label: "รายการรักษา",
          class: "narrow-spacing",
        },
        {
          key: "treatType",
          label: "ประเภท",
          class: "narrow-spacing",
        },
        {
          key: "doctor",
          label: "แพทย์",
          class: "narrow-spacing",
          formatter(value, key, item) {
            return `${item.doctorFirstName} ${item.doctorLastName}`;
          },
        },
        {
          key: "amount",
          label: "ค่ารักษาสุทธิ",
          class: "text-right",
        },
        {
          key: "paid",
          label: "ชำระ",
          class: "text-right",
        },
        {
          key: "overdue",
          label: "ค้างจ่าย",
          class: "text-right text-danger",
        },
        {
          key: "overdueDelFlag",
          label: "สถานะ",
          class: "",
        },
        {
          key: "overdueDelFullName",
          label: "ลบโดย",
          class: "narrow-spacing",
        },
        {
          key: "overdueDelDate",
          label: "ลบเมื่อ",
          class: "narrow-spacing",
        },
      ],
      visibleRows: [],
      itemsType: [],
      options: [
        { value: 0, text: "ทั้งหมด" },
        { value: 1, text: "ปกติ" },
        { value: 2, text: "ลบแล้ว" },
      ],
      itemsStatus: 1,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      userInfo: "moduleUser/getUserInfo",
      getReportDateRange: "moduleReport/getReportDateRange",
      dateRangeShortcuts: "moduleMaster/getDateRangeShortcuts",
    }),
    filteredOverdueList() {
      let list = this.overdueList.filter((i) => {
        if (this.itemsStatus === 0) return true;
        if (this.itemsStatus === 1) return !i.overdueDelFlag;
        if (this.itemsStatus === 2) return i.overdueDelFlag;
      });
      list = list.map((i) => {
        let _rowVariant = i.overdueDelFlag ? "secondary" : "";
        return { ...i, _rowVariant };
      });
      if (this.itemsType.length === 0) {
        return list;
      } else {
        return list.filter((i) => this.itemsType.includes(i.treatType));
      }
    },
    dateFrom() {
      return this.dateRange[0]
        ? moment(this.dateRange[0]).startOf("day").format()
        : "";
    },
    dateTo() {
      return this.dateRange[1]
        ? moment(this.dateRange[1]).endOf("day").format()
        : "";
    },
    today() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      return today;
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchOverdueItemsReport: "moduleReport/fetchOverdueItemsReport",
      setStateReportDateRange: "moduleReport/setStateReportDateRange",
    }),
    init() {},
    moment() {
      return moment();
    },
    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("D MMM YY");
      } else {
        return "";
      }
    },
    formatTime(date) {
      if (date) {
        return moment(date).locale("th").format("HH:mm");
      } else {
        return "";
      }
    },
    formatType(code) {
      switch (code) {
        case "01":
          return "รักษาทั่วไป";
        case "02":
          return "แพ็กเกจจัดฟัน";
        case "03":
          return "ยา และอุปกรณ์";
      }
    },
    getFullname(item) {
      if (item.overdueDelFirstName && item.overdueDelLastName) {
        return item.overdueDelFirstName + " " + item.overdueDelLastName;
      } else {
        return "";
      }
    },

    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
    sum(arg) {
      return (
        this.visibleRows.reduce((accum, item) => {
          return accum + parseFloat(item[arg]);
        }, 0.0) || 0
      );
    },
    generatePDf() {
      eventBus.$emit(
        "generatePDF",
        this.$refs["ReceiptIncomeTable"].$el,
        this.$refs.header,
        this.$refs.footer
      );
    },

    sendEmail() {
      //รอ api
    },
    fetchReport() {
      if (this.dateFrom === "" || this.dateTo === "") return;

      this.setStateReportDateRange(this.dateRange);
      this.isLoading = true;
      this.fetchOverdueItemsReport({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      })
        .then((res) => {
          this.overdueList = res.data.report;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    excelExport(id) {
      document.querySelectorAll(".sr-only").forEach((e) => e.remove());

      var tbl = document.getElementById(id);
      var ws = XLSX.utils.table_to_sheet(tbl);
      let filename = "report.xlsx";
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws);

      XLSX.writeFile(wb, filename, {
        // type: "string",
      });
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
  mounted() {
    if (this.getReportDateRange[0] && this.getReportDateRange[1]) {
      this.dateRange = this.getReportDateRange;
    } else {
      this.dateRange = [this.today, this.today];
    }
  },
  updated() {},
};
</script>

<style>
</style>
