<template>
  <div>
    <b-row>
      <b-col cols="12" md="4" lg="5">
        <b-form-group
          label-size="sm"
          label="วันที่:"
          label-cols="3"
          label-align="right"
          invalid-feedback="*กรุณาเลือกวันที่"
          :state="Boolean(dateRange[0] && dateRange[1])"
          class="mb-2"
        >
          <date-picker
            v-model="dateRange"
            type="date"
            range
            format="DD MMM YY"
            :shortcuts="dateRangeShortcuts"
            :disabled-date="notAfterToday"
            placeholder="เลือกวันที่ต้องการค้นหา"
            input-class="form-control form-control-sm"
          ></date-picker>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="5">
        <b-form-group class="mb-2 float-right">
          <b-form-checkbox-group size="sm" class="" v-model="itemsType">
            <b-form-checkbox :value="1">รักษาทั่วไป</b-form-checkbox>
            <b-form-checkbox :value="2">แพ็กเกจจัดฟัน</b-form-checkbox>
            <b-form-checkbox :value="3">ยา และอุปกรณ์</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="3" lg="2">
        <b-button
          class="float-right mb-2"
          variant="primary"
          @click="fetchReport"
        >
          แสดงข้อมูล
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="8">
        <b-col cols="12">
          <b-form-group
            label-size="sm"
            label-cols="2"
            label="รายการรักษา:"
            label-align-sm="right"
            class="mb-1"
          >
            <b-input-group>
              <multiselect
                class="col p-0 multiselect-h-31px"
                :style="{ minHeight: '31px' }"
                v-model="selectedTx"
                :options="getGroupTxListNoFav"
                :limit="5"
                :multiple="true"
                :close-on-select="false"
                group-values="libs"
                group-label="category"
                :group-select="true"
                placeholder="ค้นหาการรักษาที่ต้องการกรอง"
                :custom-label="customTxDrugsLabel"
                track-by="nameTh"
                selectLabel
                selectedLabel
                deselectLabel
              >
                <template #option="props">
                  <span v-if="props.option.$isLabel">
                    {{ props.option.$groupLabel }}
                  </span>
                </template>
                <template #clear="props">
                  <div
                    v-if="selectedTx.length"
                    class="multiselect__clear"
                    @mousedown.prevent.stop="clearTxFilter(props)"
                  ></div>
                </template>
                <span slot="noResult">ไม่พบรายการ กรุณาเปลี่ยนคำค้นหา</span>
              </multiselect>
              <b-input-group-append>
                <b-button
                  size="sm"
                  variant="info"
                  @click="$refs.nameInputModal.openModal()"
                >
                  <i class="far fa-star"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label-size="sm"
            label-cols="2"
            label="ยาและสินค้า:"
            label-align-sm="right"
            class="mb-2"
          >
            <b-input-group>
              <multiselect
                class="col p-0 multiselect-h-31px"
                :style="{ minHeight: '31px' }"
                v-model="selectedDrug"
                :options="getGroupedItemListNoFav"
                :limit="5"
                :multiple="true"
                :close-on-select="false"
                group-values="libs"
                group-label="category"
                :group-select="true"
                placeholder="ค้นหาการยาและสินค้าที่ต้องการกรอง"
                :custom-label="customTxDrugsLabel"
                track-by="nameTh"
                selectLabel
                selectedLabel
                deselectLabel
              >
                <template #option="props">
                  <span v-if="props.option.$isLabel">
                    {{ props.option.$groupLabel }}
                  </span>
                </template>
                <template #clear="props">
                  <div
                    v-if="selectedDrug.length"
                    class="multiselect__clear"
                    @mousedown.prevent.stop="clearDrugFilter(props)"
                  ></div>
                </template>
                <span slot="noResult">ไม่พบรายการ กรุณาเปลี่ยนคำค้นหา</span>
              </multiselect>
              <b-input-group-append>
                <b-button
                  size="sm"
                  variant="info"
                  @click="$refs.nameInputModal.openModal()"
                >
                  <i class="far fa-star"></i>
                </b-button>
              </b-input-group-append> </b-input-group
          ></b-form-group>
        </b-col>
      </b-col>
      <b-col>
        <FavFilter
          ref="favFilter"
          storageName="favouriteFilter"
          :height="68"
          @listClicked="applySelectedTx"
        />
      </b-col>
    </b-row>

    <!-- {{favTxValue}} -->
    <!-- {{selectedTx}} -->
    <div
      id="report-elements"
      ref="reportElements"
      :class="{ 'my-fullscreen': isFullscreen }"
    >
      <b-row>
        <b-col cols="7" md="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            size="sm"
            first-number
            last-number
            pills
            limit="10"
          ></b-pagination>
        </b-col>
        <b-col>
          <b-button
            size="sm"
            class="float-right"
            variant="info"
            @click="excelExport('branchItemsIncomeTable')"
          >
            <i class="fas fa-file-excel"></i>
          </b-button>
          <b-button
            size="sm"
            class="float-right mr-2"
            variant="secondary"
            @click="isFullscreen = !isFullscreen"
          >
            <i class="fas fa-expand"></i>
          </b-button>
        </b-col>
      </b-row>

      <b-table
        id="branchItemsIncomeTable"
        ref="branchItemsIncomeTable"
        class="my-0 sticky-footer"
        small
        sort-icon-left
        hover
        head-variant="light"
        :busy="tableBusy"
        :items="filteredList"
        :fields="fields"
        sort-by="creationDt"
        :sort-desc="true"
        v-model="visibleRows"
        :responsive="true"
        caption="Double click เพื่อดูใบเสร็จ/ DF และ CF คำนวนจากการรับชำระของคนไข้ในแต่ละครั้ง"
        :sticky-header="tableHeight"
        :per-page="perPage"
        :current-page="currentPage"
        show-empty
        @row-dblclicked="openReceipt"
      >
        <template #cell(index)="row">{{ row.item.row }}.</template>
        <template #cell(creationDt)="row">{{ formatDate(row.value) }}</template>
        <template #cell(treatType)="row">{{ formatType(row.value) }}</template>

        <template #custom-foot>
          <b-tr variant="secondary" class="text-bold text-right">
            <td class="text-left">รวม</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>

            <td></td>
            <td></td>
            <td></td>
            <td>{{ sum("paid") }}</td>
            <td></td>
            <!-- <td class="text-danger">{{ sum("overdue") }}</td> -->
            <td>{{ sum("processingFeeBaht") }}</td>
            <td>{{ sum("paidDf") }}</td>
            <td>{{ sum("paidCf") }}</td>
          </b-tr>
        </template>
        <template #empty>
          <p class="text-center text-muted my-2">ไม่มีข้อมูลให้แสดง</p>
        </template>
      </b-table>
    </div>
    <NameInput ref="nameInputModal" @submitName="addFav" />
    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/main";

// import PdfExport from "@/components/PdfExport";

import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";

import XLSX from "xlsx";

import FavFilter from "@/components/FavFilter";
import NameInput from "@/components/modal/NameInput";

export default {
  name: "BranchItemsIncomeReport",
  components: {
    Dialog,
    Loading,
    FavFilter,
    NameInput,
  },
  data() {
    return {
      isLoading: false,

      dateRange: [],

      //table
      tableBusy: false,
      itemsList: [],
      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "creationDt",
          label: "วันที่",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "doctorFullName",
          label: "แพทย์",
          class: "narrow-spacing",
        },
        {
          key: "dn",
          label: "DN.",
          class: "narrow-spacing",
        },
        {
          key: "patientFullName",
          label: "ชื่อคนไข้",
          class: "narrow-spacing",
        },
        {
          key: "itemName",
          label: "รายการรักษา",
          class: "narrow-spacing",
        },
        {
          key: "treatType",
          label: "ประเภท",
          class: "narrow-spacing",
        },
        {
          key: "price",
          label: "ค่ารักษา",
          class: "text-right",
        },
        {
          key: "finalDiscount",
          label: "ส่วนลด",
          class: "text-right",
        },
        {
          key: "amount",
          label: "ค่ารักษาสุทธิ",
          class: "text-right",
        },
        {
          key: "paid",
          label: "ชำระ",
          class: "text-right",
        },
        {
          key: "overdue",
          label: "ค้างชำระ",
          class: "text-right text-danger",
        },
        {
          key: "processingFeeBaht",
          label: "ค่าธรรมเนียม",
          class: "text-right",
        },
        {
          key: "paidDf",
          label: "DF",
          class: "text-right",
        },
        {
          key: "paidCf",
          label: "CF",
          class: "text-right",
        },
        // {
        //   key: "button",
        //   label: "",
        //   class: "p-0 align-middle"
        // }
      ],
      visibleRows: [],
      itemsType: [],
      selectedTx: [],
      selectedDrug: [],
      favTxValue: [],

      // pagination
      currentPage: 1,
      perPage: 100,

      isFullscreen: false,
      tableTopPos: null,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      userInfo: "moduleUser/getUserInfo",
      getTreatBy: "moduleAppointment/getTreatBy",
      getItemBy: "moduleAppointment/getItemBy",
      getReportDateRange: "moduleReport/getReportDateRange",
      getGroupTxListNoFav: "moduleAppointment/getGroupTxListNoFav", //ย้าย grouping เข้าไปใน appointment module
      getGroupedItemListNoFav: "moduleAppointment/getGroupedItemListNoFav", //ย้าย grouping เข้าไปใน appointment module
      dateRangeShortcuts: "moduleMaster/getDateRangeShortcuts",
    }),
    filterItemId() {
      return this.selectedTx.map(i => i.id);
    },
    filterDrugId() {
      return this.selectedDrug.map(i => i.id);
    },

    filteredList() {
      let filteredData = this.itemsList;
      if (this.itemsType.length === 0) {
        filteredData = this.itemsList;
      } else {
        filteredData = this.itemsList.filter(i =>
          this.itemsType.includes(i.treatType)
        );
      }
      if (this.filterItemId.length > 0) {
        filteredData = this.itemsList.filter(i => {
          return this.filterItemId.includes(parseInt(i.txItemId));
        });
      }
      if (this.filterDrugId.length > 0) {
        filteredData = this.itemsList.filter(i => {
          return this.filterDrugId.includes(parseInt(i.drugItemId));
        });
      }
      return filteredData
        .slice(0)
        .reverse()
        .map((i, index) => {
          return { ...i, row: index + 1 };
        });
    },
    startDt() {
      return this.dateRange[0]
        ? moment(this.dateRange[0]).startOf("day").format()
        : "";
    },
    endDt() {
      return this.dateRange[1]
        ? moment(this.dateRange[1]).endOf("day").format()
        : "";
    },
    today() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      return today;
    },
    rows() {
      return this.filteredList.length;
    },
    tableHeight() {
      if (!this.isFullscreen) {
        return `calc(100vh - ${this.tableTopPos + 40}px)`;
      } else {
        return `calc(100vh - 90px)`;
      }
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchReportBranchReceiptItem: "moduleReport/fetchReportBranchReceiptItem",
      setStateReportDateRange: "moduleReport/setStateReportDateRange",
    }),
    init() {},
    moment() {
      return moment();
    },
    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("D MMM YY");
      } else {
        return "";
      }
    },

    resetDateRange() {
      this.dateRange = {
        startDate: null,
        endDate: null,
      };
    },
    formatType(code) {
      switch (code) {
        case 1:
          return "รักษาทั่วไป";
        case 2:
          return "แพ็กเกจจัดฟัน";
        case 3:
          return "ยา และอุปกรณ์";
      }
    },
    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
    sum(arg) {
      let num =
        this.filteredList.reduce((accum, item) => {
          return accum + parseFloat(item[arg]);
        }, 0.0) || 0;
      return Math.round(num * 100) / 100;
    },

    generatePDf() {
      eventBus.$emit(
        "generatePDF",
        this.$refs["branchItemsIncomeTable"].$el,
        this.$refs.header,
        this.$refs.footer
      );
    },
    sendEmail() {
      //รอ api
    },
    fetchReport() {
      if (this.startDt === "" || this.endDt === "") return;

      this.setStateReportDateRange(this.dateRange);
      this.isLoading = true;
      this.fetchReportBranchReceiptItem({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        startDt: this.startDt,
        endDt: this.endDt,
      })
        .then(res => {
          this.itemsList = res.data.report;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async excelExport(id) {
      document.querySelectorAll(".sr-only").forEach(e => e.remove());
      this.perPage = null;
      await this.$nextTick();
      var tbl = document.getElementById(id);
      var ws = XLSX.utils.table_to_sheet(tbl);
      let filename = "report.xlsx";
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws);

      XLSX.writeFile(wb, filename, {
        // type: "string",
      });
      this.perPage = 100;
    },

    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    openReceipt(data) {
      eventBus.$emit("openReceipt", data);
    },
    customTxDrugsLabel(option) {
      if (option.nameTh == option.nameEn) {
        return `${option.code ? "[" + option.code + "]-" : ""}${option.nameTh}`;
      } else {
        return `${option.code ? "[" + option.code + "]-" : ""}${option.nameTh}${
          option.nameEn ? " / " + option.nameEn : ""
        }`;
      }
    },
    clearTxFilter(prop) {
      this.selectedTx = [];
    },
    clearDrugFilter(prop) {
      this.selectedDrug = [];
    },
    applySelectedTx({ selectedTx, selectedDrug }) {
      this.selectedTx = selectedTx.flat();
      this.selectedDrug = selectedDrug.flat();
    },
    addFav(e) {
      let data = {
        text: e,
        selectedTx: this.selectedTx,
        selectedDrug: this.selectedDrug,
      };
      this.$refs.favFilter.addItem(data);
    },
  },
  async mounted() {
    if (this.getReportDateRange[0] && this.getReportDateRange[1]) {
      this.dateRange = this.getReportDateRange;
    } else {
      this.dateRange = [this.today, this.today];
    }
    await this.$nextTick();
    this.tableTopPos = document
      .querySelector("#branchItemsIncomeTable")
      .getBoundingClientRect().top;

    eventBus.$on("afterCancelReceipt", e => {
      this.fetchReport();
    });
  },
  updated() {},
};
</script>

<style >
</style>

<style >
.multiselect-h-38px .multiselect__tags {
  min-height: 38px;
}
.multiselect-h-31px .multiselect__tags {
  min-height: 31px;
}
</style>
