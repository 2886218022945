<template>
  <div class="mb-1">
    <!-- <div class="sticky-parent-fav-note" :style="parentStyle">
      <div id="tx-drawing-buttons" class="sticky-top mt-0 mr-0">
        <b-button
          class=""
          variant="outline-secondary"
          size="xs"
          @click="openEditModal"
          ><i class="fas fa-edit"></i
        ></b-button>
      </div>
    </div>-->
    <b-list-group>
      <b-list-group-item
        v-if="!arrayList.length"
        class="p-1"
        button
        disabled
      >สามารถบันทึกรายการกรองที่ใช้บ่อยได้...</b-list-group-item>
      <b-list-group-item
        class="p-1"
        v-for="(list, index) in arrayList"
        :key="index"
        button
        @click="handleClicked(list)"
      >
        {{ list.text }}
        <span
          class="float-right text-danger"
          variant="outline-danger"
          @click.prevent.stop="deleteItem(list, index)"
        >
          <i class="fas fa-trash-alt"></i>
        </span>
      </b-list-group-item>
    </b-list-group>
    <Dialog ref="Dialog"></Dialog>
  </div>
</template>

<script>
import Dialog from "@/components/modal/Dialog";

export default {
  name: "FavFilter",
  props: {
    storageName: String,
    height: Number
  },
  components: {
    Dialog
  },

  data() {
    return {
      selected: "",
      arrayList: []
    };
  },
  computed: {
    parentStyle() {
      return `height: ${this.height}px`;
    }
  },
  methods: {
    handleClicked(e) {
      this.$emit("listClicked", e);
    },
    addItem(e) {
      this.arrayList.push(e);
      this.save();
    },
    deleteItem(list, index) {
      this.$refs.Dialog.showAlertConfirm(
        "ลบรายการนี้ ?",
        `คุณต้องการลบรายการ "${list.text}"`
      ).then(result => {
        if (result.value) {
          this.arrayList.splice(index, 1);
          this.save();
        }
      });
    },
    save() {
      localStorage.setItem(this.storageName, JSON.stringify(this.arrayList));
    }
  },
  mounted() {
    let list = localStorage.getItem(this.storageName);
    this.arrayList = list ? JSON.parse(list) : [];
  }
};
</script>

<style>
</style>