<template>
  <div>
    <b-row
      ><b-col cols="12" md="4" lg="5" class="mb-3">
        <date-picker
          v-model="dateRange"
          type="date"
          range
          format="DD MMM YY"
          :shortcuts="dateRangeShortcuts"
          :disabled-date="notAfterToday"
          placeholder="เลือกวันที่ต้องการค้นหา"
          input-class="form-control"
        >
        </date-picker
      ></b-col>
      <b-col cols="12" md="4" lg="4" class="">
        <b-form-group
          label="ดูรายงานตาม:"
          label-cols="4"
          label-align-sm="right"
        >
          <b-form-select
            class="col"
            v-model="groupBy"
            :options="options"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4" lg="3" class="mb-3">
        <b-button
          class="float-right mb-2 ml-2"
          variant="primary"
          @click="fetchReport"
        >
          แสดงข้อมูล
        </b-button>
        <b-button
          class="float-right"
          variant="info"
          @click="excelExport('PaymentMethodTable')"
          ><i class="fas fa-file-excel"></i
        ></b-button>
      </b-col>
    </b-row>
    <b-table
      id="PaymentMethodTable"
      ref="PaymentMethodTable"
      small
      sort-icon-left
      head-variant="light"
      :busy="tableBusy"
      :items="formatedReportList"
      :fields="dynamicHeader"
      sort-by="creationDt"
      v-model="visibleRows"
      :sort-desc="true"
      :responsive="true"
      show-empty
      sticky-header="calc(100vh - 210px)"
    >
      <template #cell(index)="row">{{ row.index + 1 }}.</template>
      <template #cell(date)="row">{{ displayDate(row.value) }}</template>

      <template #custom-foot>
        <b-tr variant="secondary" class="text-bold text-right">
          <td v-for="field in dynamicHeader" :key="field.key">
            {{ sum(field.key) }}
          </td>
        </b-tr>
      </template>
      <template #empty="">
        <p class="text-center text-muted my-2">ไม่มีข้อมูลให้แสดง</p>
      </template>
    </b-table>
    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/main";

import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";

import XLSX from "xlsx";

export default {
  name: "PaymentMethodRepot",
  components: {
    Dialog,
    Loading,
  },
  data() {
    return {
      isLoading: false,

      dateRange: [],
      groupBy: "day",
      options: [
        { value: "day", text: "วัน" },
        { value: "month", text: "เดือน" },
        { value: "year", text: "ปี" },
      ],

      //table
      tableBusy: false,

      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "date",
          label: "วันที่",
        },
      ],
      reportList: [],
      rawTableHeader: [],

      visibleRows: [],

      loadingTime: 0,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      userInfo: "moduleUser/getUserInfo",
      getReportDateRange: "moduleReport/getReportDateRange",
      dateRangeShortcuts: "moduleMaster/getDateRangeShortcuts",
    }),

    dateFrom() {
      return this.dateRange[0]
        ? moment(this.dateRange[0]).startOf("day").format()
        : "";
    },
    dateTo() {
      return this.dateRange[1]
        ? moment(this.dateRange[1]).endOf("day").format()
        : "";
    },
    today() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      return today;
    },
    formatedReportList() {
      return this.reportList.map((i) => {
        let payment = i.receiptPayment.reduce((prev, curr) => {
          let obj = {};
          let key = "paymentId" + curr.keyName;
          obj[key] = curr;
          return Object.assign(prev, obj);
        }, {});
        let totalAmount = i.receiptPayment.reduce((prev, curr) => {
          return prev + curr.value;
        }, 0);
        return {
          date: i.creationDt,
          ...payment,
          totalAmount,
        };
      });
    },
    dynamicHeader() {
      let formatedHeader = this.rawTableHeader.map((i) => {
        return {
          key: "paymentId" + i.keyName + ".value",
          label: i.name,
          class: "text-right",
          thClass: "narrow-spacing",
        };
      });
      let totalAmount = {
        key: "totalAmount",
        label: "รวม",
        class: "text-right font-weight-bold",
      };
      return [...this.fields, ...formatedHeader, totalAmount];
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchReportPaymentMethod: "moduleReport/fetchReportPaymentMethod",
      setStateReportDateRange: "moduleReport/setStateReportDateRange",
    }),
    init() {},
    moment() {
      return moment();
    },
    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("D MMM YY");
      } else {
        return "";
      }
    },
    displayDate(date) {
      if (this.groupBy === "year")
        return "ปี " + moment(date, "YYYY").locale("th").format("YYYY");
      if (this.groupBy === "month")
        return moment(date, "YYY-MM").locale("th").format("MMM YY");
      return moment(date, "YYY-MM-DD").locale("th").format("D MMM YY");
    },

    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
    sum(key) {
      let splitStr = key.split(".");
      return this.visibleRows.reduce((accum, item) => {
        let data =
          splitStr.length > 1 ? item[splitStr[0]][splitStr[1]] : item[key];
        if (typeof data != "number") return null;
        return accum + data;
      }, null);
    },
    generatePDf() {
      eventBus.$emit(
        "generatePDF",
        this.$refs["PaymentMethodTable"].$el,
        this.$refs.header,
        this.$refs.footer
      );
    },

    sendEmail() {
      //รอ api
    },
    fetchReport() {
      if (this.dateFrom === "" || this.dateTo === "") return;

      this.setStateReportDateRange(this.dateRange);

      this.isLoading = true;
      this.fetchReportPaymentMethod({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        groupBy: this.groupBy,
      })
        .then((res) => {
          this.reportList = res.data.item;
          this.rawTableHeader = res.data.header.receiptPayment;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    excelExport(id) {
      document.querySelectorAll(".sr-only").forEach((e) => e.remove());

      var tbl = document.getElementById(id);
      var ws = XLSX.utils.table_to_sheet(tbl);
      let filename = "report.xlsx";
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws);

      XLSX.writeFile(wb, filename, {
        // type: "string",
      });
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
  mounted() {
    if (this.getReportDateRange[0] && this.getReportDateRange[1]) {
      this.dateRange = this.getReportDateRange;
    } else {
      this.dateRange = [this.today, this.today];
    }
  },
  updated() {},
};
</script>

<style>
</style>
