<template>
  <div>
    <b-row>
      <b-col cols="12" md="8">
        <b-form-group
          class="col px-0"
          label-cols-sm="2"
          label="แพทย์:"
          label-align-sm="right"
          label-class="font-weight-bold"
        >
          <b-input-group>
            <multiselect
              class="col p-0"
              v-model="selectedDoctor"
              :options="getDoctorList"
              :multiple="true"
              :limit="5"
              placeholder="พิมพ์ค้นหาแพทย์"
              track-by="nameTh"
              label="fullName"
              selectLabel=""
              selectedLabel=""
              deselectLabel="คลิกเพื่อลบออก"
            >
              <span slot="noResult">ไม่พบรายการ กรุณาเปลี่ยนคำค้นหา</span>
            </multiselect>
            <b-input-group-append>
              <b-button variant="info" @click="selectedDoctor = getDoctorList"
                >เลือกทั้งหมด</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group> </b-col
      ><b-col cols="12" md="4" class="mb-1">
        <date-picker
          class="col-12 mb-2 px-0"
          v-model="dateRange"
          type="date"
          range
          format="DD MMM YY"
          :shortcuts="dateRangeShortcuts"
          :disabled-date="notAfterToday"
          placeholder="เลือกวันที่ต้องการค้นหา"
          input-class="form-control"
        >
        </date-picker>

        <b-button
          class="float-right mb-2 ml-2"
          variant="primary"
          @click="fetchReport"
        >
          แสดงข้อมูล
        </b-button>
        <b-button
          class="col-auto float-right"
          variant="info"
          @click="excelExport"
        >
          <i class="fas fa-file-excel"></i
        ></b-button>
      </b-col>
    </b-row>
    <b-tabs pills>
      <b-tab title="สรุปรวม" class="px-0">
        <h6 class="mt-2">
          รายงานของวันที่
          {{ formatDate(dateRange[0]) + " - " + formatDate(dateRange[1]) }}
        </h6>
        <b-table
          id="doctors-summary-table"
          class="mt-2"
          small
          sort-icon-left
          head-variant="light"
          :busy="tableBusy"
          :items="filteredDoctorIncomeList"
          :fields="sumFields"
          :responsive="true"
          show-empty
          sticky-header="calc(100vh - 210px)"
        >
          <template #cell(index)="row">{{ row.index + 1 }}.</template>
        </b-table>
      </b-tab>
      <b-tab title="รายงานตามวัน" class="px-0"
        ><div id="doctorsIncomeTable" ref="doctorsIncomeTable" class="mt-2">
          <div v-for="doctor in filteredDoctorIncomeList" :key="doctor.uid">
            <h6>แพทย์: {{ doctor.user.fullName }}</h6>
            <b-table
              :id="'doctors-report-' + doctor.uid"
              small
              sort-icon-left
              head-variant="light"
              :busy="tableBusy"
              :items="doctor.reportDoctorIncomeList"
              :fields="fields"
              sort-by="creationDt"
              v-model="visibleRows"
              :sort-desc="true"
              :responsive="true"
              show-empty
              sticky-header="calc(100vh - 210px)"
            >
              <template #cell(index)="row">{{ row.index + 1 }}.</template>
              <template #cell(creationDt)="row">{{
                formatDate(row.value)
              }}</template>
              <template #custom-foot>
                <b-tr variant="secondary" class="text-bold text-right">
                  <td class="text-left">รวม</td>
                  <td></td>

                  <td></td>
                  <td></td>
                  <td></td>
                  <!-- <td>{{ sum("price", row.items) }}</td> -->
                  <!-- <td>{{ sum("finalDiscount", row.items) }}</td> -->
                  <!-- <td>{{ sum("amount", row.items) }}</td> -->
                  <td>{{ doctor.sumReport.paid }}</td>
                  <td></td>
                  <!-- <td>{{ sum("overdue", doctor) }}</td> -->
                  <td>{{ doctor.sumReport.processingFeeBaht }}</td>

                  <td>{{ doctor.sumReport.paidDf }}</td>
                  <td>{{ doctor.sumReport.paidCf }}</td>
                </b-tr>
              </template>
              <template #empty="">
                <p class="text-center text-muted my-2">ไม่มีข้อมูลให้แสดง</p>
              </template>
            </b-table>
            <hr />
          </div></div
      ></b-tab>
    </b-tabs>

    <!-- <div class="d-none">
      <div ref="header" id="printHeader">
        <h4>รายงานรายได้แพทย์ทุกท่าน</h4>
        <h6>
          วันที่
          {{
            formatDate(dateRange.startDate) +
            "-" +
            formatDate(dateRange.endDate)
          }}
        </h6>
        <span class="text-right">
          พิมพ์วันที่ {{ formatDate(today) }} จัดทำโดย
          {{ userInfo.firstNameTh + " " + userInfo.lastNameTh }}</span
        >
      </div>
      <div ref="footer" id="printFooter">
        <h3>Footer</h3>
      </div>
    </div> -->
    <!-- <PdfExport ref="pdfExport" /> -->
    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/main";

// import PdfExport from "@/components/PdfExport";

import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";
import XLSX from "xlsx";

export default {
  name: "BranchItemsIncomeReport",
  components: {
    Dialog,
    Loading,
  },
  data() {
    return {
      isLoading: false,

      dateRange: [],
      selectedDoctor: [],

      //table
      tableBusy: false,
      doctorsIncomeList: [],
      fields: [
        {
          key: "index",
          label: "",
          class: "narrow-spacing",
        },
        {
          key: "creationDt",
          label: "วันที่",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "price",
          label: "ค่ารักษา",
          class: "text-right narrow-spacing",
        },
        {
          key: "finalDiscount",
          label: "ส่วนลด",
          class: "text-right narrow-spacing",
        },
        {
          key: "amount",
          label: "ค่ารักษาสุทธิ",
          class: "text-right narrow-spacing",
        },
        {
          key: "paid",
          label: "ชำระ",
          class: "text-right narrow-spacing",
        },
        {
          key: "overdue",
          label: "ค้างชำระ",
          class: "text-right text-danger narrow-spacing",
        },
        {
          key: "processingFeeBaht",
          label: "ค่าธรรมเนียม",
          class: "text-right narrow-spacing",
        },
        {
          key: "paidDf",
          label: "DF",
          class: "text-right narrow-spacing",
        },
        {
          key: "paidCf",
          label: "CF",
          class: "text-right narrow-spacing",
        },
      ],
      visibleRows: [],
      sumFields: [
        {
          key: "index",
          label: "",
          class: "narrow-spacing",
        },
        {
          key: "user.fullName",
          label: "แพทย์",
          sortable: true,
          class: "narrow-spacing",
        },

        {
          key: "sumReport.paid",
          label: "ชำระ",
          class: "text-right narrow-spacing",
        },

        {
          key: "sumReport.processingFeeBaht",
          label: "ค่าธรรมเนียม",
          class: "text-right narrow-spacing",
        },
        {
          key: "sumReport.paidDf",
          label: "DF",
          class: "text-right narrow-spacing",
        },
        {
          key: "sumReport.paidCf",
          label: "CF",
          class: "text-right narrow-spacing",
        },
      ],
      sumKeys: ["paid", "processingFeeBaht", "paidDf", "paidCf"],

      loadingTime: 0,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      userInfo: "moduleUser/getUserInfo",
      getDoctorList: "moduleUser/getDoctorList",
      getReportDateRange: "moduleReport/getReportDateRange",
      dateRangeShortcuts: "moduleMaster/getDateRangeShortcuts",
    }),
    dateFrom() {
      return this.dateRange[0]
        ? moment(this.dateRange[0]).startOf("day").format()
        : "";
    },
    dateTo() {
      return this.dateRange[1]
        ? moment(this.dateRange[1]).endOf("day").format()
        : "";
    },
    today() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      return today;
    },
    filteredDoctorIncomeList() {
      let listAllDoctors = this.doctorsIncomeList;
      listAllDoctors.forEach(doctor => {
        doctor.sumReport = this.sumKeys.reduce((accumulator, value) => {
          return { ...accumulator, [value]: 0 };
        }, {});

        this.sumKeys.forEach(field => {
          doctor.sumReport[field] = this.calculateSum(
            doctor.reportDoctorIncomeList,
            field
          );
        });
      });
      let selectedUid = this.selectedDoctor.map(i => i.uid);
      return listAllDoctors.filter(doctor => selectedUid.includes(doctor.uid));
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchReportBranchDoctorIncome:
        "moduleReport/fetchReportBranchDoctorIncome",
      setStateReportDateRange: "moduleReport/setStateReportDateRange",
    }),
    init() {},
    moment() {
      return moment();
    },
    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("D MMM YY");
      } else {
        return "";
      }
    },
    resetDateRange() {
      this.dateRange = {
        startDate: null,
        endDate: null,
      };
    },
    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
    sum(arg, row) {
      let num = row.reduce((accum, item) => {
        return accum + parseFloat(item[arg]);
      }, 0.0);
      return Math.round(num * 100) / 100;
    },
    calculateSum(array, property) {
      const total = array.reduce((accumulator, object) => {
        return accumulator + object[property];
      }, 0);

      return total;
    },
    sendEmail() {
      //รอ api
    },
    fetchReport() {
      if (this.dateFrom === "" || this.dateTo === "") return;

      this.isLoading = true;
      this.setStateReportDateRange(this.dateRange);
      this.fetchReportBranchDoctorIncome({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      })
        .then(res => {
          this.doctorsIncomeList = res.data.report;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    excelExport() {
      document.querySelectorAll(".sr-only").forEach(e => e.remove());
      let wb = XLSX.utils.book_new();
      let filename = "report.xlsx";

      let tbl = document.getElementById("doctors-summary-table");
      tbl.insertRow(0);
      tbl.insertRow(0);
      let ws = XLSX.utils.table_to_sheet(tbl);
      XLSX.utils.sheet_add_aoa(ws, [
        [`วันที่: ${this.dateFrom} - ${this.dateTo}`],
      ]);
      XLSX.utils.book_append_sheet(wb, ws, "สรุปรวม");

      this.filteredDoctorIncomeList.forEach(i => {
        let id = "doctors-report-" + i.uid;
        let tbl = document.getElementById(id).cloneNode(true);
        tbl.insertRow(0);
        tbl.insertRow(0);
        tbl.insertRow(0);

        let ws = XLSX.utils.table_to_sheet(tbl);

        XLSX.utils.sheet_add_aoa(ws, [
          [`แพทย์: ${i.user.fullName}`],
          [`วันที่: ${this.dateFrom} - ${this.dateTo}`],
        ]);

        XLSX.utils.book_append_sheet(wb, ws, i.user.fullName.slice(0, 30));
      });

      XLSX.writeFile(wb, filename, {
        // type: "string",
      });
      return;
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
  mounted() {
    this.selectedDoctor = this.getDoctorList;

    if (this.getReportDateRange[0] && this.getReportDateRange[1]) {
      this.dateRange = this.getReportDateRange;
    } else {
      this.dateRange = [this.today, this.today];
    }
  },
  updated() {},
};
</script>

<style>
</style>
