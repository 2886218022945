<template>
  <div>
    <b-row
      ><b-col cols="12" sm="6" md="5" lg="5">
        <b-form-group
          block
          label="วันที่:"
          label-cols="2"
          label-align="right"
          invalid-feedback="*กรุณาเลือกวันที่"
          :state="Boolean(dateRange[0] && dateRange[1])"
        >
          <date-picker
            v-model="dateRange"
            type="date"
            range
            format="DD MMM YY"
            :shortcuts="dateRangeShortcuts"
            :disabled-date="notAfterToday"
            placeholder="เลือกวันที่ต้องการค้นหา"
            input-class="form-control"
          >
          </date-picker>
          <template #description>
            <span v-if="moreThanOneDay">
              การขอรายงานที่มีช่วงเวลามากกว่า 1 วัน
              รายงานจะถูกจัดส่งให้ทางอีเมลเท่านั้น
            </span></template
          >
        </b-form-group></b-col
      >

      <b-col cols="12" sm="6" md="4" lg="5">
        <b-form-group label="สถานะใบเสร็จ:" label-cols="5" label-align="right">
          <b-form-select
            v-model="receiptStatus"
            :options="receiptStatusOpt"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="3" lg="2">
        <b-button
          v-if="moreThanOneDay"
          class="float-right mb-2"
          variant="primary"
          @click="sendEmail"
        >
          ส่งรายงานผ่าน Email
        </b-button>
        <b-button
          v-else
          class="float-right mb-2"
          variant="primary"
          @click="fetchReport"
        >
          แสดงข้อมูล
        </b-button>
      </b-col>
    </b-row>
    <div
      v-if="!moreThanOneDay"
      id="report-elements"
      ref="reportElements"
      :class="{ 'my-fullscreen': isFullscreen }"
    >
      <b-row>
        <b-col cols="7" sm="8">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            size="sm"
            first-number
            last-number
            pills
            limit="10"
          ></b-pagination>
        </b-col>
        <b-col>
          <b-button
            class="float-right"
            size="sm"
            variant="info"
            @click="excelExport('ReceiptIncomeTable')"
            ><i class="fas fa-file-excel"></i
          ></b-button>
          <b-button
            class="float-right mr-2"
            size="sm"
            variant="secondary"
            @click="isFullscreen = !isFullscreen"
            ><i class="fas fa-expand"></i
          ></b-button>
        </b-col>
      </b-row>

      <b-table
        id="ReceiptIncomeTable"
        ref="ReceiptIncomeTable"
        class="my-0 sticky-footer"
        small
        sort-icon-left
        hover
        head-variant="light"
        :sticky-header="tableHeight"
        :busy="tableBusy"
        :items="filteredReceiptsList"
        :fields="dynamicFields"
        sort-by="creationDt"
        v-model="visibleRows"
        :sort-desc="true"
        :responsive="true"
        caption="Double click เพื่อดูใบเสร็จ"
        show-empty
        :per-page="perPage"
        :current-page="currentPage"
        @row-dblclicked="openReceipt"
      >
        <template #cell(index)="row">{{ row.item.row }}.</template>
        <template #cell(creationDt)="row">{{ formatDate(row.value) }}</template>
        <template #cell(time)="row">{{
          formatTime(row.item.creationDt)
        }}</template>
        <template #cell(status)="row"
          ><span
            v-if="row.item.cancelFlag"
            v-b-popover.hover.bottomleft="cancelPopoverConfig(row.item)"
          >
            ยกเลิก
          </span></template
        >
        <template #custom-foot>
          <b-tr variant="secondary" class="text-bold text-right">
            <td v-for="field in dynamicFields" :key="field.key">
              {{ sum(field.key) }}
            </td>
          </b-tr>
        </template>
        <template #empty="">
          <p class="text-center text-muted my-2">ไม่มีข้อมูลให้แสดง</p>
        </template>
      </b-table>
    </div>

    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/main";

import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";

import XLSX from "xlsx";

export default {
  name: "ReceiptIncomeReport",
  components: {
    Dialog,
    Loading,
  },
  data() {
    return {
      isLoading: false,

      dateRange: [],
      receiptStatus: 0,
      receiptStatusOpt: [
        {
          value: null,
          text: "ทั้งหมด",
        },
        {
          value: "0",
          text: "ปกติ",
        },
        {
          value: "1",
          text: "ยกเลิก",
        },
      ],
      //table
      tableBusy: false,
      receiptsList: [],
      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "creationDt",
          label: "วันที",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "time",
          label: "เวลา",
          class: "narrow-spacing",
        },
        {
          key: "receiptNo",
          label: "เลขที่ใบเสร็จ",
          class: "narrow-spacing",
        },
        {
          key: "dn",
          label: "DN.",
          class: "narrow-spacing",
        },
        {
          key: "patientFullName",
          label: "ชื่อคนไข้",
          class: "narrow-spacing",
        },
        {
          key: "price",
          label: "ค่ารักษา",
          class: "text-right",
        },
        {
          key: "finalDiscount",
          label: "ส่วนลด",
          class: "text-right",
        },
        {
          key: "amount",
          label: "ค่ารักษาสุทธิ",
          class: "text-right",
        },
        {
          key: "paid",
          label: "ชำระ",
          class: "text-right",
        },
        {
          key: "overdue",
          label: "ค้างชำระ",
          class: "text-right text-danger",
        },
      ],
      visibleRows: [],
      excludeKeys: ["price", "finalDiscount", "amount", "overdue"],

      // pagination
      currentPage: 1,
      perPage: 100,

      isFullscreen: false,
      tableTopPos: null,

      loadingTime: 0,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      userInfo: "moduleUser/getUserInfo",
      getReportDateRange: "moduleReport/getReportDateRange",
      paymentMethodList: "moduleUser/getPaymentMethodList",
      dateRangeShortcuts: "moduleMaster/getDateRangeShortcuts",
    }),
    filteredReceiptsList() {
      return this.receiptsList
        .slice(0)
        .reverse()
        .map((i, index) => {
          let receiptPayment = i.receiptPayment.reduce((acc, curr) => {
            let obj = {};
            obj[curr.id] = curr;
            return Object.assign(acc, obj);
          }, {});
          let _rowVariant = i.cancelFlag ? "secondary" : null;
          return { ...i, receiptPayment, row: index + 1, _rowVariant };
        });
    },
    dynamicFields() {
      let paymentFields = this.paymentMethodList.map(i => {
        return {
          key: `receiptPayment.${i.id}.amount`,
          label: i.name,
          class: "text-right",
          thClass: "narrow-spacing",
        };
      });
      let statusField = { key: "status", label: "" };
      let buttonField = {
        key: "button",
        label: "",
        class: "p-0 align-middle",
      };
      return [...this.fields, ...paymentFields, statusField, buttonField];
    },
    dateFrom() {
      return this.dateRange[0]
        ? moment(this.dateRange[0]).startOf("day").toISOString(true)
        : "";
    },
    dateTo() {
      return this.dateRange[1]
        ? moment(this.dateRange[1]).endOf("day").toISOString(true)
        : "";
    },
    moreThanOneDay() {
      if (!this.dateFrom || !this.dateTo) return false;
      const df = moment(this.dateFrom);
      const dt = moment(this.dateTo);

      // Calculate the difference in days between the current date and the given date
      const differenceInDays = dt.diff(df, "days");

      // Check if the difference is more than one day
      // return differenceInDays > 0;
      return false
    },
    today() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      return today;
    },
    rows() {
      return this.filteredReceiptsList.length;
    },
    tableHeight() {
      if (!this.isFullscreen) {
        return `calc(100vh - ${this.tableTopPos + 40}px)`;
      } else {
        return `calc(100vh - 90px)`;
      }
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchReportBranchReceipt: "moduleReport/fetchReportBranchReceipt",
      emailReportBranchReceipt: "moduleReport/emailReportBranchReceipt",
      setStateReportDateRange: "moduleReport/setStateReportDateRange",
      fetchPaymentMethodList: "moduleUser/fetchPaymentMethodList",
    }),
    init() {
      this.fetchPaymentMethodList({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
      })
        .then(res => {
          this.paymentMethods = res.data;
        })
        .finally(() => {
          // this.isLoading = false;
        });
    },
    moment() {
      return moment();
    },
    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("D MMM YY");
      } else {
        return "";
      }
    },
    formatTime(date) {
      if (date) {
        return moment(date).locale("th").format("HH:mm");
      } else {
        return "";
      }
    },

    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
    sum(key) {
      if (this.excludeKeys.includes(key)) return;
      let splitStr = key.split(".");
      return this.filteredReceiptsList.reduce((accum, item) => {
        let data;
        if (splitStr.length > 1) {
          if (
            item[splitStr[0]] &&
            item[splitStr[0]][splitStr[1]] &&
            item[splitStr[0]][splitStr[1]][splitStr[2]]
          ) {
            data = item[splitStr[0]][splitStr[1]][splitStr[2]];
          } else {
            data = 0;
          }
        } else {
          data = item[key];
        }
        if (typeof data != "number") return null;
        return Math.round((accum + data) * 100) / 100;
      }, null);
    },
    generatePDf() {
      eventBus.$emit(
        "generatePDF",
        this.$refs["ReceiptIncomeTable"].$el,
        this.$refs.header,
        this.$refs.footer
      );
    },

    async sendEmail() {
      try {
        await this.emailReportBranchReceipt({
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          receiptStatus: this.receiptStatus,
        });
        this.$refs.Dialog.showAlertInfo(
          "รายงานจะถูกส่งไปทางอีเมลของท่านภายใน 15 นาที"
        );
      } catch (err) {
        console.error(err);
      }
      //รอ api
    },
    fetchReport() {
      if (this.dateFrom === "" || this.dateTo === "") return;
      this.setStateReportDateRange(this.dateRange);

      this.isLoading = true;
      this.fetchReportBranchReceipt({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        receiptStatus: this.receiptStatus,
      })
        .then(res => {
          this.receiptsList = res.data.report;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    cancelPopoverConfig(e) {
      return {
        html: true,
        variant: "danger",
        title: () => {
          return `ใบเสร็จ ${e.receiptNo} ถูกยกเลิก`;
        },
        content: () => {
          return `โดย:<em> ${e.cancelFirstName ?? ""} ${
            e.cancelLastName ?? ""
          }</em><br>
          วันเวลา:<em> ${this.formatDate(e.cancelDt) ?? ""} ${
            this.formatTime(e.cancelDt) ?? ""
          }</em><br>
          สาเหตุ:<em> ${e.cancelRemark ?? ""}</em>`;
        },
      };
    },
    async excelExport(id) {
      this.perPage = null;
      await this.$nextTick();
      document.querySelectorAll(".sr-only").forEach(e => e.remove());

      var tbl = document.getElementById(id);
      var ws = XLSX.utils.table_to_sheet(tbl);
      let filename = "report.xlsx";
      var wb = XLSX.utils.book_new();
      await XLSX.utils.book_append_sheet(wb, ws);

      await XLSX.writeFile(wb, filename, {
        // type: "string",
      });
      this.perPage = 100;
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    openReceipt(row) {
      eventBus.$emit("openReceipt", row);
    },
  },

  async mounted() {
    if (this.getReportDateRange[0] && this.getReportDateRange[1]) {
      this.dateRange = this.getReportDateRange;
    } else {
      this.dateRange = [this.today, this.today];
    }

    // await this.$nextTick();
    this.tableTopPos = document
      .querySelector("#ReceiptIncomeTable")
      .getBoundingClientRect().top;

    eventBus.$on("afterCancelReceipt", e => {
      this.fetchReport();
    });
  },
  updated() {},
};
</script>

<style>
.sr-only {
  display: none;
}
</style>
