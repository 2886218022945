<template>
  <div>
    <b-row
      ><b-col cols="12" sm="8">
        <b-form-group
          block
          label="วันที่:"
          label-cols="2"
          label-align="right"
          invalid-feedback="*กรุณาเลือกวันที่"
          :state="Boolean(dateRange[0] && dateRange[1])"
        >
          <date-picker
            v-model="dateRange"
            type="date"
            range
            format="DD MMM YY"
            :shortcuts="dateRangeShortcuts"
            :disabled-date="notAfterToday"
            placeholder="เลือกวันที่ต้องการค้นหา"
            input-class="form-control"
          >
          </date-picker> </b-form-group
      ></b-col>
      <b-col cols="12" sm="4">
        <b-button
          class="float-right mb-2 ml-2"
          variant="primary"
          @click="fetchReport"
        >
          แสดงข้อมูล
        </b-button>
        <b-button
          class="float-right"
          variant="info"
          @click="excelExport('CancelReceiptTable')"
          ><i class="fas fa-file-excel"></i
        ></b-button>
      </b-col>
    </b-row>
    <b-table
      id="CancelReceiptTable"
      ref="CancelReceiptTable"
      small
      sort-icon-left
      head-variant="dark"
      variant="danger"
      :busy="tableBusy"
      :items="filteredReceiptsList"
      :fields="fields"
      sort-by="creationDt"
      v-model="visibleRows"
      :sort-desc="true"
      :responsive="true"
      show-empty
      sticky-header="calc(100vh - 210px)"
    >
      <template #cell(index)="row">{{ row.index + 1 }}.</template>
      <template #cell(cancelDt)="row">{{
        formatDate(row.item.cancelDt)
      }}</template>
      <template #cell(time)="row">{{ formatTime(row.item.cancelDt) }}</template>
      <template #cell(paid)="row"
        >{{ row.value
        }}{{ row.item.overdue === 0 ? "" : `(${row.item.overdue})` }}</template
      >
      <template #cell(cancelFirstName)="row">{{
        row.item.cancelFirstName + " " + row.item.cancelLastName
      }}</template>

      <template #custom-foot>
        <b-tr variant="secondary" class="text-bold text-right">
          <td class="text-left">รวม</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>

          <td>{{ sum("amount") }}</td>
          <td>
            {{ sum("paid") }}
          </td>

          <td>
            {{ sum("overdue") }}
          </td>
          <td></td>
          <td></td>
        </b-tr>
      </template>
      <template #empty="">
        <p class="text-center text-muted my-2">ไม่มีข้อมูลให้แสดง</p>
      </template>
    </b-table>

    <!-- <div class="d-none">
      <div ref="header" id="printHeader">
        <h4>รายงานรายได้สาขาตามรายการรักษา</h4>
        <h6>
          วันที่
          {{
            formatDate(dateRange.startDate) +
            "-" +
            formatDate(dateRange.endDate)
          }}
        </h6>
        <span class="text-right">
          พิมพ์วันที่ {{ formatDate(today) }} จัดทำโดย
          {{ userInfo.firstNameTh + " " + userInfo.lastNameTh }}</span
        >
      </div>
      <div ref="footer" id="printFooter">
        <h3>Footer</h3>
      </div>
    </div> -->
    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/main";

import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";

import XLSX from "xlsx";

export default {
  name: "CancelReceiptReport",
  components: {
    Dialog,
    Loading,
  },
  data() {
    return {
      isLoading: false,

      dateRange: [],
      //table
      tableBusy: false,
      receiptsList: [],
      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "cancelDt",
          label: "วันที่ยกเลิก",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "time",
          label: "เวลา",
          class: "narrow-spacing",
        },
        {
          key: "receiptNo",
          label: "เลขที่ใบเสร็จ",
          class: "narrow-spacing",
        },
        {
          key: "dn",
          label: "DN.",
          class: "narrow-spacing",
        },
        {
          key: "patientFullName",
          label: "ชื่อคนไข้",
          class: "narrow-spacing",
        },

        {
          key: "amount",
          label: "ค่ารักษาสุทธิ",
          class: "text-right",
        },
        {
          key: "paid",
          label: "ชำระ",
          class: "text-right",
        },
        {
          key: "overdue",
          label: "ค้างชำระ",
          class: "text-right text-danger",
        },
        {
          key: "cancelFirstName",
          label: "ผู้ยกเลิก",
          variant: "danger",
          class: "narrow-spacing",
        },
        {
          key: "cancelRemark",
          label: "สาเหตุ",
          variant: "danger",
          class: "narrow-spacing",
        },
      ],
      visibleRows: [],

      loadingTime: 0,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      userInfo: "moduleUser/getUserInfo",
      getReportDateRange: "moduleReport/getReportDateRange",
      dateRangeShortcuts: "moduleMaster/getDateRangeShortcuts",
    }),
    filteredReceiptsList() {
      return this.receiptsList;
    },
    dateFrom() {
      return this.dateRange[0]
        ? moment(this.dateRange[0]).startOf("day").format()
        : "";
    },
    dateTo() {
      return this.dateRange[1]
        ? moment(this.dateRange[1]).endOf("day").format()
        : "";
    },
    today() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      return today;
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchReportBranchReceipt: "moduleReport/fetchReportBranchReceipt",
      setStateReportDateRange: "moduleReport/setStateReportDateRange",
    }),
    init() {},
    moment() {
      return moment();
    },
    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("D MMM YY");
      } else {
        return "";
      }
    },
    formatTime(date) {
      if (date) {
        return moment(date).locale("th").format("HH:mm");
      } else {
        return "";
      }
    },

    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
    sum(arg) {
      return (
        this.visibleRows.reduce((accum, item) => {
          return accum + parseFloat(item[arg]);
        }, 0.0) || 0
      );
    },
    generatePDf() {
      eventBus.$emit(
        "generatePDF",
        this.$refs["ReceiptIncomeTable"].$el,
        this.$refs.header,
        this.$refs.footer
      );
    },

    sendEmail() {
      //รอ api
    },
    fetchReport() {
      if (this.dateFrom === "" || this.dateTo === "") return;

      this.setStateReportDateRange(this.dateRange);
      this.isLoading = true;
      this.fetchReportBranchReceipt({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        receiptStatus: 1,
      })
        .then((res) => {
          this.receiptsList = res.data.report;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    excelExport(id) {
      document.querySelectorAll(".sr-only").forEach((e) => e.remove());

      var tbl = document.getElementById(id);
      var ws = XLSX.utils.table_to_sheet(tbl);
      let filename = "report.xlsx";
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws);

      XLSX.writeFile(wb, filename, {
        // type: "string",
      });
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
  mounted() {
    if (this.getReportDateRange[0] && this.getReportDateRange[1]) {
      this.dateRange = this.getReportDateRange;
    } else {
      this.dateRange = [this.today, this.today];
    }
  },
  updated() {},
};
</script>

<style>
</style>
